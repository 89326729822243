<template>
	<div class="all_body" v-if="list.length>0">
	
		<el-row type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
					<div class="d_title" >{{langs.csqy_csqj}} | <span>{{langs.csqy_csqj_en}}</span></div>
				</div>
				<!-- <div v-if="list.length<=0" class="lf_player_img_box">
					<template v-for="(b,index) in list">
						<div class="lf_player_img_div"
							v-if="b.is_gz == '1' && ( plname == '' || b.pl_name.indexOf(plname) !=  -1)">
							<div class="lf_player_card">
								<img class="lf_player_card_img" :src="b.pl_photo" alt="">
								<div class="lf_player_card_con">
									<div class="lf_player_card_con_1">{{b.pl_name}} <img v-if="b.pl_country!=''"
											:src="b.pl_country" alt=""></div>
									<div class="lf_player_card_con_2">{{langs.Age}}：{{b.pl_year}}</div>
									<div v-if="b.pl_height!=''" class="lf_player_card_con_2">
										{{langs.Height}}：{{b.pl_height?b.pl_height+'CM':''}}</div>
									<div class="lf_player_card_con_2">
										{{langs.YearofTurningProfessional}}：{{b.pl_turned?b.pl_turned+'年':'-'}}</div>
									<div class="lf_player_card_con_2">{{langs.BestAwards}}：{{b.score}}</div>
								</div>
							</div>
							<div class="lf_player_bottom_img_box">
								<img class="lf_player_bottom_img" v-if="b.mp_logo!=''" :src="b.mp_logo" alt="">
							</div>
						</div>
					</template>
				</div> -->
			</el-col>
		</el-row>
		<Ad></Ad>
		<div class="all_search">
			<div class="left_text">{{langs.csqy_jzmq}} <text class="left_text_one"> {{list.length}}{{langs.text_ren}}</text> {{langs.csqy_cs}} <text class="left_text_two" v-if="list&&list[0]">({{langs.csqy_zjycxg}}{{list[0].maxdate}})</text></div>
			
			<div class="right_search">
				<div class="lf_match_info_box_r">
					<img  src="../assets/images/u6324.png"/>
					<div  class="my-input" >
						<el-input size="default" v-model="plname" :placeholder="langs.PleaseInput"/>
					</div>
					
					<el-button size="default" type="danger"
						style="height: 0.5rem;;background-color: rgba(192, 21, 62, 1);line-height: 0.5rem;border-radius: 0 8px 8px 0;width: 1.4rem; font-size: 0.26rem;">{{langs.psearch_search}}</el-button>
				</div>
				<div class="small_text">{{langs.csqy_czqy}}</div>
			</div>
			
		</div>
		
		<el-row type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
					<div class="lf_boxboxbox">
						<div class="lf_player_list_tit">
							<div class="lf_1">{{langs.text_Order}}</div>
							
							<div class="lf_3 heady">
								<span class="lf_9999sss" @click.stop="showSelect = !showSelect">
									{{orderArr[orderid][systemlang]}}
								</span>
								<img style="transform: rotate(90deg);" @click.stop="showSelect = !showSelect" src="../assets/images/u182.png" />
								
								<div class="select-left into" v-if="showSelect" >
									<div class="select_socl" >
										<template v-for="(z,index) in orderArr">
										<div @click.stop="changOrder(index)" class="select_item " :class="orderid==index?'select_item_hover':''">{{z[systemlang]}} 
											
										</div>
										</template>
										
									</div>	
									

								</div>
							</div>
							<div class="lf_4"></div>
						</div>
						<template v-for="(b,index) in list">
							<div class="banner" v-if="plname == ''&&index%20==0&&index!=0" v-show="bannershow && bannershow['MATCHPLAYER_CENTER'] == '1'">
								<banner @EventBack='setbannershow' :type="2" abs="MATCHPLAYER_CENTER"></banner>
							</div>
							<div class="lf_player_list_con" v-if="plname == ''||b.pl_en_name.indexOf(plname)!==-1||b.pl_cn_name.indexOf(plname)!==-1||b.wgwrid==plname">
							<router-link :style="index%2==0?'background: #fdfdfd;':''" target="_blank"
								:to="{path:'/playerInfo',query:{pl_id:b.pl_id-200000,lang:systemlang}}">
								<div class="lf_1">{{index+1}}</div>
								<div class="lf_3 hoverUnderLine"><img v-if="b.pl_country!=''" :src="b.pl_country" alt="" class="img_left"> {{b.pl_cn_name}} {{b.pl_en_name}}{{b.pl_type=="AMA"?"(A)":""}}</div>
								<div class="lf_4">
									<!-- <template v-for="(zhanzhupic,index1) in b.zhanzhu">
										<img :src="'/'+zhanzhupic" alt="" class="img_right">
									</template> -->
									<banner class="lf_zzs_logo" heightNew="0.5rem" :type="2" datatype="1" :dataurl="b.zhanzhu"></banner>
								</div>
							</router-link>
							</div>
						</template>
					
					</div>
				</div>
			</el-col>
		</el-row>
		


		
		<el-row v-if="listback.length>0" style="margin-top:0.5rem" type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
					<div class="d_title" >{{langs.text_Order_back}} | <span>{{langs.text_Order_back_en}}</span></div>
				</div>
			</el-col>
		</el-row>
		<div v-if="listback.length>0" style="height: auto;" class="all_search">
			<div class="left_text">{{langs.csqy_jzmq}} <text class="left_text_one"> {{listback.length}}{{langs.text_ren}}</text> {{langs.csqy_cs_back}} <text class="left_text_two" v-if="listback&&listback[0]">({{langs.csqy_zjycxg}}{{listback[0].maxdate}})</text></div>	
		</div>
		<!--备用球员-->
		<el-row v-if="listback.length>0" type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_match_info_box">
					<div class="lf_boxboxbox">
						<div class="lf_player_list_tit">
							<div class="lf_1">{{langs.text_Order}}</div>
							
							<div class="lf_3 heady">
								<span class="lf_9999sss" >
									{{langs.text_Order_add}}
								</span>
								<!-- <span class="lf_9999sss" @click.stop="showSelect = !showSelect">
									{{orderArr[orderid][systemlang]}}
								</span>
								<img style="transform: rotate(90deg);" @click.stop="showSelect = !showSelect" src="../assets/images/u182.png" />
								
								<div class="select-left into" v-if="showSelect" >
									<div class="select_socl" >
										<template v-for="(z,index) in orderArr">
										<div @click.stop="changOrder(index)" class="select_item " :class="orderid==index?'select_item_hover':''">{{z[systemlang]}} 
											
										</div>
										</template>
										
									</div>	
									

								</div> -->
							</div>
							<div class="lf_4"></div>
						</div>
						<template v-for="(b,index) in listback">
							<div class="banner" v-if="plname == ''&&index%20==0&&index!=0" v-show="bannershow && bannershow['MATCHPLAYER_CENTER'] == '1'">
								<banner @EventBack='setbannershow' :type="2" abs="MATCHPLAYER_CENTER"></banner>
							</div>
							<div class="lf_player_list_con" v-if="plname == ''||b.pl_en_name.indexOf(plname)!==-1||b.pl_cn_name.indexOf(plname)!==-1||b.wgwrid==plname">
							<router-link :style="index%2==0?'background: #fdfdfd;':''" target="_blank"
								:to="{path:'/playerInfo',query:{pl_id:b.pl_id-200000,lang:systemlang}}">
								<div class="lf_1">{{index+1}}</div>
								<div class="lf_3 hoverUnderLine"><img v-if="b.pl_country!=''" :src="b.pl_country" alt="" class="img_left"> {{b.pl_cn_name}} {{b.pl_en_name}}{{b.pl_type=="AMA"?"(A)":""}}</div>
								<div class="lf_4">
									<!-- <template v-for="(zhanzhupic,index1) in b.zhanzhu">
										<img :src="'/'+zhanzhupic" alt="" class="img_right">
									</template> -->
									<banner class="lf_zzs_logo" heightNew="0.5rem" :type="2" datatype="1" :dataurl="b.zhanzhu"></banner>
								</div>
							</router-link>
							</div>
						</template>
					
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<div v-else>
		<empty></empty>
	</div>
</template>

<script>
	import axios from '../axios/request';
	import Ad1 from "./Ad1.vue";
	import banner from "./banner.vue";
	import empty from "./empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				list: {},
				listback:{},
				pmlist: [],
				plname: '',
				bannershow: [],
				showSelect:false,
				orderid:0,
				orderArr:[
					{"cn":"按资格排序","en":"By Exempt Order"},
					{"cn":"按国家/地区排序","en":"By Country/Region"},
					{"cn":"按首字母排列","en":"By A~Z"}
				]
			}
		},
		name: 'Player',
		props: {
			msg: String,
			mt_id: String,
		},
		components: {
			Ad1,
			banner,
			empty
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			console.log("mt_id",this.mt_id);
			//获取banner
			this.getData();
			this.getDataback();
		},
		methods: {
			getData(){
				axios('matchs/match/matchtj.json', {
				otp: 'matchplayer',
				orderyid:this.orderid,
				mt_id: this.mt_id
			})
			.then((res) => {
				let data = res.data.data;
				if(this.systemlang == "en"){
					for(var k in data){
						var temp = data[k].pl_cn_name;
						data[k].pl_cn_name = data[k].pl_en_name;
						data[k].pl_en_name = temp;
					}
				}
				this.list = data
			});
			},
			getDataback(){
				axios('matchs/match/matchtj.json', {
				otp: 'matchplayerback',
				mt_id: this.mt_id
			})
			.then((res) => {
				let data = res.data.data;
				if(this.systemlang == "en"){
					for(var k in data){
						var temp = data[k].pl_cn_name;
						data[k].pl_cn_name = data[k].pl_en_name;
						data[k].pl_en_name = temp;
					}
				}
				this.listback = data
			});
			},
			setbannershow(abs, isshow) {
				// console.log(abs,isshow,'ssssssssssssssss');
				this.bannershow[abs] = isshow;
			},
			changOrder(index) {
				this.orderid = index;
				this.getData();
				this.showSelect = false;
			}
		}
	}
</script>

<style scoped="scoped">
	.banner {
		width: 18.5rem;
		height: 1.85rem;
		overflow: hidden;
	}
	
	.banner img {
		width: 100%;
		height: 1.85rem;
	}
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}

	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}

	.lf_tab_little_box {
		height: 30px;
		display: flex;
		align-items: center;
	}

	.lf_tab_little_box div {
		padding: 0 16px;
		height: 0.14rem;
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
	}

	.lf_tab_little_box div:nth-last-of-type(1) {
		border-right: unset;
	}

	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}

	.lf_tab_box {
		width: 330px;
		height: 30px;
		display: flex;
		align-items: center;
		margin-top: 40px;
	}

	.lf_tab_box div {
		width: 110px;
		height: 30px;
		font-size: 0.16rem;
		color: #999;
		border: 1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-radius: 5px;
	}

	.lf_tab_box div:nth-of-type(1) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.lf_tab_box div:nth-of-type(2) {
		border-radius: 0;
	}

	.lf_tab_box div:nth-of-type(3) {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	.lf_tab_box .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
	}

	.lf_opacity_div {
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 4rem;
		z-index: 99;
		padding: 0.5rem 0;
	}

	.lf_opacity_div img {
		max-width: 3.36rem;
		height: 0.65rem;
		margin: 0 auto;
		width: auto;
	}

	.lf_opacity_div_1 {
		height: 0.7rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		letter-spacing: 0.04rem;
		color: #FFFFFF;
		width: 3.36rem;
		line-height: 0.7rem;
		text-align: center;
		overflow: hidden;
		margin: 0.1rem auto 0;
		border-bottom: 1px solid #fff;
	}

	.lf_opacity_div_2 {
		width: 3.36rem;
		margin: 0.25rem auto 0;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_3 {
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_4 {
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_5 {
		width: 1rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(242, 242, 242, 0);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 1);
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.14rem;
		color: #FFFFFF;
		margin: 0.25rem auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}


	.lf_match_banner_box {
		height: 4rem;
		width: 100%;
		position: relative;
	}

	.lf_match_banner_box_img {
		height: 4rem;
	}


	.right_search{
		
		float: right;
	}

	.lf_match_info_box_r {
		display: flex;
		justify-content: flex-end;
		
		
	}
	.lf_match_info_box_r img{
		width: 0.3rem;
		height: 0.3rem;
		margin-top: 0.1rem;
		margin-right: 0.1rem;
	}
	.small_text{
		font-size: 0.18rem;
		height: 0.2rem;
		line-height: 0.2rem;
		color: #999999;
		margin-top: 0.1rem;
		margin-left: 0.56rem;
	}
	.lf_match_info_text_ {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.2rem;
		color: #999999;
		display: flex;
		align-items: center;
		justify-content: center;

		flex-shrink: 0;
	}

	.lf_player_img_box {
		padding: 0 10px 0.35rem;
		font-size: 0.14rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.lf_player_img_div {
		width: 2.95rem;
		height: 2rem;
		display: flex;
		flex-direction: column;
		background-color: rgba(250, 250, 250, 1);
		margin-right: 0.2rem;
		margin-bottom: 0.2rem;
	}

	.lf_player_img_div:nth-of-type(4n) {
		margin-right: 0;
	}

	.lf_player_bottom_img_box {
		height: 0.45rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid #f5f5f5;
	}

	.lf_player_bottom_img {
		width: 1.4rem;
		height: 0.24rem;
	}

	.lf_player_card {
		width: 100%;
		height: 1.55rem;
		display: flex;
	}

	.lf_player_card_img {
		margin: 0.16rem;
		width: 0.9rem;
		height: 0.9rem;
		border-radius: 50%;
	}

	.lf_player_card_con {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.lf_player_card_con_1 {
		font-size: 0.2rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.06rem;
	}

	.lf_player_card_con_1 img {
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}

	.lf_player_card_con_2 {
		font-size: 0.14rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.25rem;
		display: flex;
		align-items: center;
	}
	.all_search{
		width: 100%;
		overflow: hidden;

		margin-top:0.01rem;
		height:0.8rem;
		
	}
	.left_text {
		float: left;
		height: 0.3rem;
		font-size: 0.24rem;
		color: #333;
		margin-top: 0.2rem;
		line-height: 0.3rem;
		margin-left: 0.2rem;
	}

	.left_text_one {
		font-weight: bold;
	}

	.left_text_two {
		color: #999999;
		font-size: 0.18rem;
		margin-left: 0.2rem;
	}

	.lf_player_list_tit {
		width: 96%;
		display: flex;
		align-items: center;
		border-bottom: 0.01rem solid rgba(219,219,219);
		height: 0.7rem;
		margin: 0 auto;
		font-size:0.24rem;
	/* 	margin-bottom:0.2rem; */
		color:#666666
	}

	.lf_player_list_tit div,
	.lf_player_list_con div {

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_1 {
		width: 2.1rem;
	}

	.lf_3 {
		width: 9.4rem;
		padding-left: 1rem;
		position: relative;
	}

	.lf_player_list_con {
		display: flex;
		width: 96%;
		margin: 0 auto;
		height: 0.9rem;
		align-items: center;
		color: #000;
		font-size: 0.28rem;
		border-bottom: 0.01rem solid rgba(219,219,219);
		align-items: center;
	}
	.lf_player_list_con a{
		display: flex;
		align-items: center;
	}

	.lf_2 img {
		width: 0.02rem;
		height: 0.14rem;
	}

	.lf_3 {

		justify-content: flex-start !important;
		cursor: pointer;
		font-size: 0.24rem;
		width: 4rem!important;
	}
	.lf_3.heady img{
		width: 0.3rem;
		height: 0.3rem;
	}
	.lf_3 .lf_9999sss{
		color:rgb(192, 21, 62);
		font-size: 0.24rem;
	}
	.lf_3 .select-left{
		top: 0.6rem!important;
		left: 0.5rem!important;
	}
	.lf_4 {
		width: 1.5rem;
		height: 0.5rem;
	}

	.lf_4 img {

		width: 1.5rem;
		height: 0.5rem;

	}

	.img_left {
		width: 0.4rem;
	
		margin-left: -0.6rem;
		margin-right: 0.2rem;

	}

	.img_right {
		width: 0.34rem;
		height: 0.28rem;

		margin-right: 0.2rem;

	}
	
	
	
	.lf_boxboxbox {
		background-color: #FAFAFA;
		width: 100%;
		font-size: 0.24rem;
		margin-top: 0.2rem;
	}
	.lf_boxboxbox a{
		    height: 100%;
		    display: inline-flex;
		    width: 100%;
	}
	@media screen and (max-width: 768px) {
		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}
		.all_search{
			height:1.6rem;
		}
		
		.left_text{
		margin-left:0.2rem
		}
		.right_search{
			float:left;
			
		}
		
		.lf_match_info_box_r{
			width:5.2rem;
			margin-left:0.2rem
		}
		.small_text{
		
			
			margin-left: 0.8rem;
		}
		.lf_tab_tit_box {
			align-items: unset;
		}

		.lf_tab_box {
			margin-top: 0.2rem;
		}

		.lf_match_banner_box {
			height: 2.5rem;
			width: 100%;
			position: relative;
		}

		.lf_match_banner_box_img {
			height: 2.5rem;
		}

		.lf_opacity_div {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			height: 100%;
			z-index: 99;
			padding: 0.1rem 0;
		}

		.lf_opacity_div img {
			display: none;
		}

		.lf_opacity_div_1 {
			height: 0.7rem;
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 0.24rem;
			letter-spacing: 0.04rem;
			color: #FFFFFF;
			width: 3.36rem;
			line-height: 0.7rem;
			text-align: center;
			overflow: hidden;
			margin: 0.42rem auto 0;
			border-bottom: 1px solid #fff;
		}

		.lf_opacity_div_2 {
			display: none;
		}

		.lf_opacity_div_3 {
			display: none;
		}

		.lf_opacity_div_4 {
			display: none;
		}

		.lf_opacity_div_5 {
			width: 1.1rem;
			height: 0.45rem;
			padding: 0.04rem;
		}

		.lf_player_img_div {
			width: 3.45rem;
			height: 2.26rem;
		}

		.lf_player_card {
			height: 1.8rem;
		}

		.lf_player_img_div:nth-of-type(2n) {
			margin-right: 0;
		}
		.lf_player_list_con {
			display: flex;
			width: 96%;
			margin: 0 auto;
			height: 0.9rem;
			color: #000;
			font-size:0.28rem;
			border-bottom: 0.01rem solid rgba(219,219,219);
			
		}
		
		.lf_1 {
			width: 1.2rem;
		}
		
		.lf_3 {
			width: 5.5rem;
			padding-left: 1rem;
			position: relative;
		}
		
		
	
		


		.lf_match_info_box_r {
			margin-top: 0.2rem;
		}
.banner{
			width: 100%;
			height: 0.75rem;
			overflow: hidden;
			margin: 0.2rem auto;
		}
		.lf_3 .select-left{
			top:0.9rem;
			text-align: left;
			z-index: 99;
			display: block!important;
			position: absolute;
			left: -1.4rem!important;
		}
	

	}
	.headred .heady{
		font-size: 0.24rem;
		color: #fff;
	}


	.heady {
		width: 3.1rem;
		float: left;
		font-size: 0.28rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000000;
	}

	.heady img {
		width: 0.36rem;
		height: 0.36rem;
		margin-left: 0.1rem;
	}

	.headb {
		width: 4.5rem;
		float: left;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: normal;
		height: 100%;
	}

	.headp {
		width: 1.7rem;

	}

	.headm {
		width: 2rem;
	}

	.headr {
		width: 1rem;
	}

	.headpink {
		width: 17.7rem;
		
		height: 0.8rem;
		line-height: 0.8rem;
		font-size: 0.28rem;
	}
	.headpink:nth-child(odd) {
	            background: rgba(250,250,250);
	 }
	.headpink:hover{
		background-color: #FFF1F2;
	}
	
	.lf_3 .select_socl{
		flex-direction:column;
	}
	.lf_3 .select_item{
		position: relative;
		display: unset!important;
	}
	.select_item span{
		position: absolute;
		right: 0.2rem;
		color: #999!important;
		font-size: 0.24rem;
	}
</style>
<style lang="less">
	.my-input{
		border: none !important;
		height: 0.5rem;
	}
	.my-input .el-input__wrapper{
		background-color: #FAFAFA !important;  
		border: none !important;
		height: 0.5rem;
	}
	.my-input .el-input__wrapper .el-input__inner{
		background: none !important;
		border: none !important;
		font-size: 0.26rem;
		height: 0.5rem;
		line-height: 0.5rem;
	}
	.right_search .my-input .el-input__wrapper{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	
	
	
</style>